import {
  useDisclosure,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  Button,
} from '@chakra-ui/react';
import {ChevronDownIcon} from '@chakra-ui/icons';
import {useRef} from 'react';
import {useMutation} from '@tanstack/react-query';
import ResultsTableActionsColumn from '../ResultsTableActionsColumn';
import ConfirmPopup from '../../Common/ConfirmPopup';
import supabaseClient from '../../../api/supabaseClient';
import {SingleUser} from '../../../pages/UsersPage/types/GetUsersResponse';
import {AxiosInstance} from '../../../api/_AxiosInstance';

export interface UsersTableActionsColumnProps {
  user: SingleUser;
  changeUserRole: (userId: number, roleToSet: string) => void;
  onSubscriptionChange: (userId: number) => void;
  displayAsMenu?: boolean;
}

const UsersTableActionsColumn = (props: UsersTableActionsColumnProps) => {
  const {user, changeUserRole, onSubscriptionChange, displayAsMenu = false} = props;
  const cancelRef = useRef(null);

  const {isOpen, onOpen, onClose} = useDisclosure();

  const cancelSubRef = useRef(null);

  const {
    isOpen: isOpenSubScription,
    onOpen: onOpenSubscription,
    onClose: onCloseSubscription,
  } = useDisclosure();

  const roleToSet = user.role === 'admin' ? 'end-user' : 'admin';
  const mutation = useMutation({
    mutationFn: async () => {
      AxiosInstance.post('/users/change-role', {
        id: user.id,
        role: roleToSet,
      });
      await supabaseClient.schema('admin').rpc('update_or_create_user_role', {
        user_id_param: user.supabaseUID,
        new_role: roleToSet,
      });
    },
    onSuccess() {
      changeUserRole(user.id, roleToSet);
      window.toast({
        description: `${user.email} is now an ${roleToSet}.`,
        status: 'success',
        position: 'top-right',
        isClosable: true,
        duration: 5000,
      });
      onClose();
    },
    onError(e) {
      window.toast({
        description: 'Something went wrong. Please try again.',
        status: 'error',
        position: 'top',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  const subscriptionMutation = useMutation({
    mutationFn: async () => {
      await AxiosInstance.patch(`/users/${user.id}/toggle-promotional-subsciption`);
    },
    onSuccess() {
      onSubscriptionChange(user.id);
      window.toast({
        description: user.hasPromotionalSubscription
          ? `${user.email} lost promotional subscription.`
          : `${user.email} got promotional subscription.`,
        status: 'success',
        position: 'top-right',
        isClosable: true,
        duration: 5000,
      });
      onCloseSubscription();
    },
    onError(e) {
      window.toast({
        description: 'Something went wrong. Please try again.',
        status: 'error',
        position: 'top',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  let adminLabels = {
    header: 'Make admin',
    helper: 'Are you sure? This user will get admin privileges.',
  };

  if (user.role === 'admin') {
    adminLabels = {
      header: 'Make end-user',
      helper: 'Are you sure? This user will lose admin privileges.',
    };
  }

  let subscriptionLabels = {
    header: 'Give Premium access',
    helper: 'Are you sure? This user will get Premium access to the app.',
  };
  if (user.hasPromotionalSubscription) {
    subscriptionLabels = {
      header: 'Cancel Premium access',
      helper: 'Are you sure? This user will lose Premium access to the app.',
    };
  }

  return (
    <div>
      <ConfirmPopup
        onDelete={mutation.mutate}
        onCancel={onClose}
        cancelRef={cancelRef}
        isOpen={isOpen}
        loading={mutation.isPending}
        headerText={adminLabels.header}
        dialogDescriptionText={adminLabels.helper}
        confirmButtonText="Confirm"
        confirmButtonColor="blue"
      />

      <ConfirmPopup
        onDelete={subscriptionMutation.mutate}
        onCancel={onCloseSubscription}
        cancelRef={cancelSubRef}
        isOpen={isOpenSubScription}
        loading={subscriptionMutation.isPending}
        headerText={subscriptionLabels.header}
        dialogDescriptionText={subscriptionLabels.helper}
        confirmButtonText="Confirm"
        confirmButtonColor="blue"
      />
      {displayAsMenu ? (
        <Menu>
          <MenuButton
            borderColor={'#E2E8F0'}
            backgroundColor={'white'}
            as={Button}
            rightIcon={<ChevronDownIcon />}>
            More
          </MenuButton>
          <MenuList>
            <MenuItem onClick={onOpen}>{adminLabels.header}</MenuItem>
            <MenuItem onClick={onOpenSubscription}>{subscriptionLabels.header}</MenuItem>
          </MenuList>
        </Menu>
      ) : (
        <ResultsTableActionsColumn
          menuItems={[
            {
              label: adminLabels.header,
              onClick: onOpen,
            },
            {
              label: subscriptionLabels.header,
              onClick: onOpenSubscription,
            },
          ]}
        />
      )}
    </div>
  );
};
export default UsersTableActionsColumn;
