import supabaseClient from '../api/supabaseClient';
import {AxiosInstance} from '../api/_AxiosInstance';
import {UserRepository} from '@amityco/ts-sdk';

export interface UploadUserProfilePictureOptions {
  file: Blob;
  supabaseUserId: string;
  oldPictureLocation?: string | null;
  userId: number;
}

export async function uploadUserProfilePicture(options: UploadUserProfilePictureOptions) {
  const {file, supabaseUserId, oldPictureLocation, userId} = options;

  const fileNameCropped = `${supabaseUserId}-profile-picture-cropped-${Date.now()}.jpeg`;

  const croppedFile = new File([file], fileNameCropped, {
    type: 'image/jpeg',
    lastModified: Date.now(),
  });

  const {data: croppedFileData, error: croppedFileError} = await supabaseClient.storage
    .from('avatars')
    .upload(`${supabaseUserId}/${fileNameCropped}`, croppedFile);

  if (croppedFileError) {
    console.error('Error uploading file:', croppedFileError);
    return null;
  }

  if (oldPictureLocation) {
    await supabaseClient.storage.from('avatars').remove([oldPictureLocation]);
  }

  const {
    data: {publicUrl: publicCroppedFileUrl},
  } = supabaseClient.storage.from('avatars').getPublicUrl(croppedFileData.path);

  await AxiosInstance.post('/users/set-profile-picture-url', {
    profilePictureUrl: publicCroppedFileUrl,
    supabaseProfilePictureLocation: croppedFileData.path,
  });

  UserRepository.updateUser(userId.toString(), {
    avatarCustomUrl: publicCroppedFileUrl,
  });

  return {
    publicCroppedFileUrl,
    croppedFileData,
  };
}
