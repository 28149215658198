/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import {
  Avatar,
  Card,
  CardBody,
  Box,
  Input,
  Heading,
  Button,
  Flex,
  FormErrorMessage,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import {EditIcon} from '@chakra-ui/icons';
import {ReactElement, useEffect, useState} from 'react';
import {useGlobalStore} from '../../store/globalStore.ts';
import 'react-image-crop/dist/ReactCrop.css';
import {useMutation, useQuery} from '@tanstack/react-query';
import {AxiosInstance} from '../../api/_AxiosInstance.ts';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {User} from '../../types/User.ts';
import supabaseClient from '../../api/supabaseClient.ts';
import queryClient from '../../api/queryClient.ts';
import DeleteConfirmPopup from '../../components/Common/DeleteConfirmPopup.tsx';
import {uploadUserProfilePicture} from '../../helpers/imageUpload.ts';
import UploadImageModal from '../../components/UploadImageModal/UploadImageModal.tsx';

const schema = yup.object().shape(
  {
    firstName: yup
      .string()
      .max(50, 'First Name must be at most 50 characters')
      .matches(/^[A-Za-z ]*$/, 'First Name must contain only letters')
      .when('firstName', {
        is: (value: string | any[]) => value?.length > 0,
        then: rule => rule.min(2, 'First Name must be at least 2 characters'),
      }),
    lastName: yup
      .string()
      .max(50, 'Last Name must be at most 50 characters')
      .matches(/^[A-Za-z ]*$/, 'Last Name must contain only letters')
      .when('lastName', {
        is: (value: string | any[]) => value?.length > 0,
        then: rule => rule.min(2, 'Last Name must be at least 2 characters'),
      }),
  },
  [
    ['firstName', 'firstName'],
    ['lastName', 'lastName'],
  ],
);

type FormData = yup.InferType<typeof schema>;

const ProfilePage = (): ReactElement => {
  const state = useGlobalStore(state => state);
  const [displayEditPicture, setDisplayEditPicture] = useState<string>('none');

  const [isOpenDeleteConfirmPopup, setIsOpenDeleteConfirmPopup] = useState<boolean>(false);
  const [isOpenUploadImageModal, setIsOpenUploadImageModal] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors, isDirty},
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    values: {
      firstName: '',
      lastName: '',
    },
  });

  const userDetailsQuery = useQuery({
    queryKey: ['user-me'],
    queryFn: async () => {
      const {data} = await AxiosInstance.get<User>(`/users/me`);

      return data;
    },
  });

  const updateProfileMutation = useMutation({
    mutationFn: async (data: FormData) => {
      const {data: user} = await AxiosInstance.put<User>('/users', {
        firstName: data.firstName || null,
        lastName: data.lastName || null,
      });
      queryClient.setQueryData(['user-me'], user);
    },
    onSuccess() {
      window.toast({
        description: `Profile updated successfully.`,
        status: 'success',
        position: 'top-right',
        isClosable: true,
        duration: 5000,
      });
    },
    onError(e) {
      window.toast({
        description: 'Something went wrong. Please try again.',
        status: 'error',
        position: 'top',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  const removeProfilePictureMutation = useMutation({
    mutationFn: removeProfilePicture,
    onSuccess() {
      window.toast({
        description: `Profile picture removed!`,
        status: 'success',
        position: 'top-right',
        isClosable: true,
        duration: 5000,
      });
    },
    onError(e) {
      window.toast({
        description: 'Something went wrong. Please try again.',
        status: 'error',
        position: 'top',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  useEffect(() => {
    if (userDetailsQuery.isSuccess && userDetailsQuery.data) {
      const {firstName, lastName} = userDetailsQuery.data;
      setValue('firstName', firstName);
      setValue('lastName', lastName);
    }
  }, [userDetailsQuery.isFetched, setValue]);

  const onSubmit = (data: FormData) => {
    updateProfileMutation.mutate(data);
  };

  async function uploadAvatar(blob: Blob) {
    const response = await uploadUserProfilePicture({
      file: blob,
      supabaseUserId: state.supabaseAuthSession!.user.id,
      oldPictureLocation: userDetailsQuery.data?.supabaseProfilePictureLocation,
      userId: userDetailsQuery.data!.id,
    });

    if (!response) {
      throw new Error('Error uploading profile picture');
    }

    queryClient.setQueryData(['user-me'], (prevData: User) => {
      return {
        ...prevData,
        profilePictureUrl: response.publicCroppedFileUrl,
        supabaseProfilePictureLocation: response.croppedFileData.path,
      };
    });
  }

  async function removeProfilePicture() {
    await supabaseClient.storage
      .from('avatars')
      .remove([userDetailsQuery.data!.supabaseProfilePictureLocation!]);

    await AxiosInstance.post('/users/set-profile-picture-url', {
      profilePictureUrl: null,
      supabaseProfilePictureLocation: null,
    });

    queryClient.setQueryData(['user-me'], (prevData: User) => {
      return {
        ...prevData,
        profilePictureUrl: null,
        supabaseProfilePictureLocation: null,
      };
    });
    setIsOpenDeleteConfirmPopup(false);
  }

  return (
    <Box>
      <Heading mb={4}>Profile</Heading>
      <Card maxWidth="container.lg">
        <CardBody>
          <Flex flexDirection="column" width="fit-content">
            <Box position="relative">
              <FormLabel>Profile Picture</FormLabel>
              {userDetailsQuery.data?.profilePictureUrl && (
                <Avatar
                  size="2xl"
                  cursor="pointer"
                  onMouseEnter={() => {
                    setDisplayEditPicture('flex');
                  }}
                  onMouseLeave={() => {
                    setDisplayEditPicture('none');
                  }}
                  src={userDetailsQuery.data?.profilePictureUrl}
                />
              )}

              {!userDetailsQuery.data?.profilePictureUrl && (
                <Avatar
                  size="2xl"
                  cursor="pointer"
                  onMouseEnter={() => {
                    setDisplayEditPicture('flex');
                  }}
                  onMouseLeave={() => {
                    setDisplayEditPicture('none');
                  }}
                />
              )}
              <Flex
                onMouseEnter={() => {
                  setDisplayEditPicture('flex');
                }}
                onMouseLeave={() => {
                  setDisplayEditPicture('none');
                }}
                position="absolute"
                left={0}
                right={0}
                bottom={0}
                top={0}
                justifyContent={'center'}
                alignItems={'center'}
                style={{display: displayEditPicture}}>
                <Button
                  onClick={() => setIsOpenUploadImageModal(true)}
                  leftIcon={<EditIcon />}>
                  Edit
                </Button>
              </Flex>
            </Box>
            {userDetailsQuery.data?.profilePictureUrl && (
              <Button
                onClick={() => setIsOpenDeleteConfirmPopup(true)}
                colorScheme="red"
                marginTop={2}>
                Remove
              </Button>
            )}
          </Flex>

          <UploadImageModal
            isOpen={isOpenUploadImageModal}
            onClose={() => setIsOpenUploadImageModal(false)}
            headerText="Upload Image"
            onImageUpload={b => {
              const blob = b[0].blob;
              return uploadAvatar(blob);
            }}
            aspect={1}
            sizes={[]}
            uploadOriginalSize
            circularCrop
          />
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl my={5}>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                id="email"
                placeholder="Email"
                value={userDetailsQuery.data?.email ?? ''}
                isDisabled
              />
            </FormControl>
            <FormControl isInvalid={!!errors.firstName} my={5}>
              <FormLabel htmlFor="firstName">First Name</FormLabel>
              <Input id="firstName" placeholder="First Name" {...register('firstName')} />
              <FormErrorMessage>{errors.firstName?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.lastName} my={5}>
              <FormLabel htmlFor="lastName">Last Name</FormLabel>
              <Input id="lastName" placeholder="Last Name" {...register('lastName')} />
              <FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
            </FormControl>
            <Flex justifyContent="flex-end">
              <Button
                colorScheme="green"
                isDisabled={!isDirty || updateProfileMutation.isPending}
                type="submit">
                Save
              </Button>
            </Flex>
          </form>
        </CardBody>
      </Card>
      <DeleteConfirmPopup
        isOpen={isOpenDeleteConfirmPopup}
        headerText="Remove Profile Picture"
        loading={removeProfilePictureMutation.isPending}
        onCancel={() => setIsOpenDeleteConfirmPopup(false)}
        onDelete={removeProfilePictureMutation.mutate}
        cancelRef={null}
      />
    </Box>
  );
};

export default ProfilePage;
